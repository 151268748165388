.container {
    min-height: 88vh;
    padding: 20px;
}

.mainContainer {
    display: flex;
    flex-direction: column;
}

.summaryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-secondary-background);
    padding: 40px 0px;
}

.fullName {
    text-align: center;
}

.dataContainer {
    padding: 40px 0px;
}

.dataRow > label {
    margin: 20px;
}

.txtField {
    margin: 20px;
    width: 85%;
}

.userImgContainer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 30px;
}

.userImg {
    width: 200px;
    height: 200px;
    margin-top: 30px;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    justify-content: right;
    gap: 10px;
    padding: 0px 20px;
}

.reviewsTitle {
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
}

.reviewCount {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    text-align: center;
}

.extraBtnContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

@media (min-width: 510px) {
    .reviewsContainer {
        display: flex;
    }
}

@media (min-width: 1000px) {
    .reviewsContainer {
        display: block;
    }

    .mainContainer {
        flex-direction: row;
    }

    .summaryContainer {
        width: 30%;
    }

    .dataContainer {
        margin-left: 100px;
        margin-top: 100px;
    }

    .txtField {
        margin: 20px;
        width: 250px;
    }

    .btnContainer {
        flex-direction: row;
    }

    .extraBtnContainer {
        justify-content: left;
    }
}

@media (min-width: 1500px) {
    .txtField {
        width: 400px;
    }
}

@media (min-width: 1600px) {
    .reviewsContainer {
        display: flex;
    }
}
