.container {
    background-color: var(--color-primary);
    width: 100vw;
    display: flex;
    justify-content: space-between;
    height: 60px;
    box-shadow: 0px -5px 18px black;
    z-index: 999;
    padding-left: 2%;
}

.candyLogo {
    position: relative;
    margin: 5px;
    margin-left: 15px;
    width: 195px;
    height: 50px;
}

.navbarItem {
    color: white;
    border: 2px solid transparent;
    letter-spacing: 2px;
    transition: all 600ms ease;
    margin-right: 25px;
}

.navbarItem:hover {
    border-bottom: 2px solid var(--color-tertiary);
    color: var(--color-tertiary);
}

.navbarList {
    align-self: center;
    margin-right: 5%;
}
