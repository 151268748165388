.container {
    min-height: 88vh;
    padding: 20px;
}

.searchContainer {
    margin-left: 20px;
}

.searchBtn {
    min-width: 200px
}

.itemTxtField {
    margin: 10px;
}

.userCard {
    max-width: 600px;
    padding: 20px;
    margin: 20px;
}

.editBtn {
    margin-top: 10px;
    min-width: 100px;
}