.container {
    min-height: 88vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.itemTxtField {
    min-width: 300px;
}
