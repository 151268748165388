.pageContainer {
    min-height: 88vh;
}

.reportInfoContainer {
    margin: 30px;
    max-width: 40vw;
}

.reportInfoLabel {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
}

.announcementsLeft {
    margin: 40px 20px;
}

.articleContainer {
    display: flex;
    margin: 0px 20px;
}

.articleImgContainer {
    max-height: 700px;
    width: 50%;
    margin: 20px
}

.serviceContainer {
    display: flex;
    margin: 0px 20px;
}

.serviceImgContainer {
    max-height: 700px;
    width: 500px;
    margin: 20px
}

.imgList {
    height: 100%;
}

.infoContainer {
    padding: 20px;
}

.articleLbl {
    font-weight: bold;
}

.serviceLbl {
    font-weight: bold;
}

.actionsContainer {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin: 20px
}

.noArticlesLeftTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.noArticlesLeftText {
    margin-top: 40px;
    text-align: center;
}

.noServicesLeftTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.noServicesLeftText {
    margin-top: 40px;
    text-align: center;
}
