.pageContainer {
    min-height: 88vh;
    padding: 20px;
}

.pageHeader {
    margin-bottom: 20px;
}

.groupInfo {
    padding: 20px;
}

.groupName {
    margin: 10px 0;
}

.groupDescription {
    color: #808080;
}

.usersContainer {
    padding: 20px;
    max-height: 50vh;
    overflow: scroll;
}

.userRow {
    color: #4b4b4b;
}
