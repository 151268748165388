@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto-Regular.ttf);
}

:root {
  --default-font-family: 'Roboto';
  --font-size-lg: 2rem;
  --font-size-md: 1.3rem;
  --font-size-sm: 1rem;
  --color-primary: #01135b;
  --color-secondary: #1976d2;
  --color-tertiary: #00edbf;
  --color-main-background: #e4e4e4;
  --color-secondary-background: #f2f2f2;
  --color-contrast: #ccc;
  --color-secondary-contrast: #dfdfdf;
  --color-red-icon: #f50057;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: var(--default-font-family);
}

html {
  background-color: var(--color-main-background);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
