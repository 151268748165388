.openedNav,
.container {
    background-color: var(--color-primary);
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 60px;
    box-shadow: 0px -5px 18px black;
    transition: all 600ms;
}

.openedNav {
    width: 100vw;
}

.candyLogo {
    position: relative;
    margin: 5px;
    width: 195px;
    height: 50px;
}

.hamburgerBtn {
    color: var(--color-contrast);
    z-index: 5000;
}

.menuContainer {
    background: white;
    color: black;
    text-align: center;
    width: 100vw;
    height: 100vh;
    max-height: unset;
    max-width: unset;
    box-shadow: 5px 5px 25px black;
    box-shadow: 0px 5px 18px black inset;
    left: 0 !important;
    margin-top: 0px;
    padding-top: 30px;
    overflow: hidden;
    transition: all 600ms ease !important;
}

.menuItem {
    letter-spacing: 2px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    margin-bottom: 5px;
}
