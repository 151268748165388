.container {
    padding: 50px 20%;
}

.reviewSection {
    margin-top: 50px;
}

.subtext {
    margin-bottom: 10px;
}

.twoColumns {
    display: flex;
    gap: 80px;
    align-items: center;
    margin: 50px 0px;
}
