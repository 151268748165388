.openedNav,
.container {
    background-color: white;
    font-family: var(--default-font-family);
    color: black;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    height: 45px;
    width: 100%;
    transition: all 600ms ease;
}

.openedNav {
    width: 100vw;
}

.msg {
    margin-right: 5%;
}

.icon {
    height: 25px;
    width: 25px;
}
