.pageContainer {
    min-height: 88vh;
    padding: 20px;
}

.pageHeader {
    margin-bottom: 20px;
}

.groupInfo {
    padding: 20px;
}

.groupName {
    margin: 10px 0;
}

.groupDescription {
    color: #808080;
}

.fieldsContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.itemTxtField {
    min-width: 300px;
}
