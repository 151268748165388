.pageContainer {
    height: calc(100vh - 185px);
}

.flex {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 20px 30px 10px;
}

.serviceContainer {
    width: 100%;
    margin: 0px 20px;
}

.infoContainer {
    padding: 20px;
}

.dataRow {
    display: flex;
    gap: 5px;
}
.dataItem {
    width: 30%
}

.actionsContainer {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin: 20px
}

.noServicesLeftTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.noServicesLeftText {
    margin-top: 40px;
    text-align: center;
}

.searchContainer {
    display: flex;
    gap: 20px;
}
.searchInput {
    width: 400px;
    margin: 0 0 30px 30px;
}
.searchButton {
    height: 3rem;
}

.List {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 310px);
    width: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
}

.List-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0 0 40px 0;
    padding: 5px;
    overflow: hidden;
}
