.container {
    height: 100vh;
    width: 320px;
    display: flex;
    margin: auto;
}

.heading {
    margin-top: 13%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    background-color: var(--color-tertiary);
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
}

.form {
    width: 100%;
    margin-top: 5px;
}

.signInBtn {
    margin-top: 16px;
    margin-bottom: 16px;
}

@media only screen and (min-width: 700px) {
    .container {
        width: 350px;
        display: flex;
        margin: auto;
    }
}
