.pageContainer {
    min-height: 88vh;
}

.announcementsLeft {
    margin: 40px 20px;
}

.serviceContainer {
    width: 100%;
    margin: 0px 20px;
}

.infoContainer {
    padding: 20px;
}

.dataRow {
    display: flex;
    gap: 5px;
}
.dataItem {
    width: 30%
}

.actionsContainer {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin: 20px
}

.noServicesLeftTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.noServicesLeftText {
    margin-top: 40px;
    text-align: center;
}