.pageContainer {
    min-height: 88vh;
    padding: 50px 20%;
}

.itemCard {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-bottom: 20px;
    max-width: 700px;
}

.itemLbl {
    margin: 10px 0px;
}

.itemTxtField {
    margin: 10px;
}

.formSelect {
    margin: 10px;
}