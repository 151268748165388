.pageContainer {
    margin-left: 20px;
    min-height: 88vh;
    padding: 20px;
}

.userGroupContainer {
    margin: 20px;
    padding: 20px;
}

.userGroupTitle {
    font-size: 26px;
}

.userGroupDescription {
    font-size: 20px;
    color: #808080;
}

.btnsContainer {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.createGroupBtn {
    margin: 20px 0;
}