.footerMainContainer {
    background-color: var(--color-primary);
    color: white;
    font-size: 0.5rem;
    letter-spacing: 2px;
    padding: 10px;
}

.options {
    margin-bottom: 10px;
    border-bottom: 1px solid grey;
}

.terms {
    padding-bottom: 0px;
    border-bottom: 1px solid grey;
}

@media (min-width: 630px) {
    .footerMainContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'cookies privacy'
            'legal terms';
    }

    .cookies {
        grid-area: cookies;
        margin-right: 15px;
    }

    .privacy {
        grid-area: privacy;
    }

    .legal {
        grid-area: legal;
        margin-right: 15px;
    }

    .terms {
        grid-area: terms;
        margin-bottom: 10px;
    }
}

@media (min-width: 1030px) {
    .footerMainContainer {
        display: flex;
        justify-content: center;
        padding: 0px;
        height: 60px;
    }

    .options {
        border-bottom: none;
        margin-left: 45px;
        align-self: center;
        margin-bottom: 0px;
    }

    .terms {
        border-bottom: none;
        margin-left: 45px;
        align-self: center;
        margin-bottom: 0px;
    }
}
